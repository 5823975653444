































import {
  computed,
  defineComponent,
  ref,
  toRefs,
  watch
} from '@vue/composition-api';

import { ANNOTATION_UI_PROPS } from '@/components/annotations/props';
import VideoPlayback from './VideoPlayback.vue';
import VideoControls from './VideoControls.vue';
import VideoProvider from './VideoProvider.vue';
import { useMockAnnotation, useVideoAnnotationLabels } from './functions';
import { firstTruthy, or } from '@/utils';
import LoadingModal from '@/components/LoadingModal.vue';

export default defineComponent({
  name: 'VideoAnnotation',
  props: ANNOTATION_UI_PROPS,
  components: {
    VideoPlayback,
    VideoControls,
    VideoProvider,
    LoadingModal
  },
  setup(props) {
    const { activeAsset } = toRefs(props);

    const assetId = computed(() => activeAsset.value.id);

    // Time in video playback
    const currentTime = ref(0);
    // Target time where video should seek
    const targetTime = ref(0);

    const playing = ref(false);
    const duration = ref(0);

    const {
      annotation: assetAnnotationFromServer,
      loading: loadingAssetAnnotation,
      error: errorLoadingAssetAnnotation,
      mutate: reloadAssetAnnotation,
      loadedForAssetId: assetAnnotationLoadedForAssetId
    } = useMockAnnotation({ assetId, duration });

    const labelsFromServer = useVideoAnnotationLabels(
      assetAnnotationFromServer
    );

    async function onAssetChange() {
      playing.value = false;
      targetTime.value = 0;
    }

    const loading = or(loadingAssetAnnotation);
    const error = firstTruthy(errorLoadingAssetAnnotation);

    watch(activeAsset, onAssetChange, { immediate: true });

    return {
      assetId,
      currentTime,
      targetTime,
      playing,
      duration,
      loading,
      error,
      labelsFromServer
    };
  }
});
