










































import {
  defineComponent,
  reactive,
  Ref,
  toRefs,
  watch
} from '@vue/composition-api';

function useSliderState(currentTime: Ref<number>, duration: Ref<number>) {
  const state = reactive({
    min: 0,
    max: 0,
    value: 0
  });

  watch(
    currentTime,
    function() {
      state.value = currentTime.value;
    },
    { immediate: true }
  );

  watch(
    duration,
    function() {
      state.max = duration.value;
    },
    { immediate: true }
  );

  return state;
}

export default defineComponent({
  props: {
    playing: {
      required: true,
      type: Boolean
    },
    currentTime: {
      required: true,
      type: Number
    },
    targetTime: {
      required: true,
      type: Number
    },
    duration: {
      required: true,
      type: Number
    }
  },
  setup(props, { emit }) {
    const { currentTime, duration, playing } = toRefs(props);

    const sliderState = useSliderState(currentTime, duration);

    function togglePlay() {
      emit('update:playing', !playing.value);
    }

    function onSliderClick(ev) {
      console.log(`Slider clicked`, ev);
      emit('update:targetTime', ev);
    }

    function formatTime(seconds: number) {
      return [Math.floor((seconds / 60) % 60), Math.floor(seconds % 60)]
        .join(':')
        .replace(/\b(\d)\b/g, '0$1');
    }

    return { slider: sliderState, togglePlay, formatTime, onSliderClick };
  }
});
