import {
  computed,
  reactive,
  ref,
  Ref,
  toRefs,
  unref,
  watch
} from '@vue/composition-api';
import _findKey from 'lodash/findKey';
import _range from 'lodash/range';
import paper from 'paper';
import { until } from '@vueuse/core';

import { AnnotatedAsset, Asset, FetchMedia } from '@/types';
import { VideoAnnotationLabel, VideoAnnotationLabelFrame } from './types';
import { Box } from '../geometry';
import { useHitManager } from './hits';
import { ANNOTATION_TOOLS, MouseTool } from './tools';
import { PaperScope } from 'paper/dist/paper-core';

function arrayBufferToUrl(buf: ArrayBuffer) {
  const blob = new Blob([buf]);
  const url = URL.createObjectURL(blob);
  return url;
}

/** Use video media data as source URL */
export function useActiveVideoMedia(
  asset: Ref<Asset>,
  fetchMedia: Ref<FetchMedia>
) {
  const state = reactive({
    loading: false,
    error: null as Error | null,
    src: null as string | null,
    mediaType: null as string | null
  });

  async function mutate() {
    state.error = null;
    state.src = null;
    state.mediaType = null;

    if (!asset.value) {
      return;
    }

    const assetId = asset.value.id;

    const medias = asset.value.media;

    const videoMediaSlug = _findKey(medias, val =>
      val.media_type.startsWith('video/')
    );

    if (!videoMediaSlug) {
      const mediaTypes = Object.values(medias)
        .map(media => media.media_type)
        .join(',');
      state.error = new Error(`No videos found in media: ${mediaTypes}`);
      return;
    }

    const mediaType = medias[videoMediaSlug].media_type;

    /** TODO Start returning simply URL once range requests are supported so users can seek
    state.src = medias[videoMediaSlug].url;
    state.mediaType = mediaType;
    return;
    */

    state.loading = true;

    try {
      const mediaArrayBuffer = await fetchMedia.value({
        assetId,
        mediaSlug: videoMediaSlug,
        mediaType
      });
      const url = arrayBufferToUrl(mediaArrayBuffer);
      state.src = url;
      state.mediaType = mediaType;
    } catch (error) {
      state.error = error;
    } finally {
      state.loading = false;
    }
  }

  watch(asset, mutate, { immediate: true });

  return { ...toRefs(state), mutate };
}

export function useMockAnnotation({
  assetId,
  duration
}: {
  assetId: Ref<string>;
  duration: Ref<number>;
}) {
  const state = reactive({
    annotation: ref(null as AnnotatedAsset | null),
    loading: false,
    error: null as Error | null,
    loadedForAssetId: null as string | null
  });

  async function mutate() {
    state.annotation = null;
    state.loadedForAssetId = null;

    if (!assetId.value || duration.value === 0) {
      return;
    }

    function makeLabels() {
      const dur = duration.value;

      const steps = _range(5);

      const startOffset = dur * 0.1;
      const endOffset = dur * 0.9;

      const startPosX = 0.1;
      const startPosY = 0.25;
      const endPosX = 0.7;
      const endPosY = 0.45;

      const width = 0.1;
      const endWidth = 0.3;

      const height = 0.15;
      const endHeight = 0.35;

      const frames = steps.map(step => {
        return {
          timeOffset:
            startOffset + (step * (endOffset - startOffset)) / steps.length,
          x: startPosX + (step * (endPosX - startPosX)) / steps.length,
          y: startPosY + (step * (endPosY - startPosY)) / steps.length,
          width: width + (step * (endWidth - width)) / steps.length,
          height: height + (step * (endHeight - height)) / steps.length
        };
      });

      const labelPerson = {
        class: 'Person',
        objectId: '1',
        frames
      };

      return [labelPerson];
    }

    const annotation: AnnotatedAsset = {
      id: 'fake',
      asset: {
        id: assetId.value,
        created_at: new Date().toISOString()
      },
      labels: makeLabels()
    };

    state.annotation = annotation;
    state.loadedForAssetId = assetId.value;
  }

  watch([assetId, duration], mutate, { immediate: true });

  return { ...toRefs(state), mutate };
}

export const parseVideoAnnotationLabel = (label: any): VideoAnnotationLabel => {
  function parseFrame(frame: any): VideoAnnotationLabelFrame {
    const { x, y, width, height, timeOffset } = frame;
    return {
      timeOffset: timeOffset,
      geometry: Box.fromXYWH({ x, y, width, height })
    };
  }

  return {
    objectId: label.objectId,
    label: label.label,
    frames: label.frames.map((frame: any) => parseFrame(frame))
  };
};

export function useVideoAnnotationLabels(
  annotation: Ref<AnnotatedAsset | null>
) {
  const labels = computed(() => {
    if (!annotation.value) {
      return [];
    }
    return annotation.value.labels.map(label =>
      parseVideoAnnotationLabel(label)
    );
  });
  return labels;
}

function fitViewToImage(scope: paper.PaperScope, imageRaster: paper.Raster) {
  // https://github.com/paperjs/paper.js/issues/1688
  const viewBounds = scope.view.bounds;
  const scaleRatio = Math.min(
    viewBounds.width / imageRaster.bounds.width,
    viewBounds.height / imageRaster.bounds.height
  );
  scope.view.translate(viewBounds.center.subtract(imageRaster.bounds.center));
  scope.view.scale(scaleRatio);
}

function createImageRaster(scope: paper.PaperScope) {
  const size = new paper.Size(0, 0);
  const imageRaster = new scope.Raster(size, scope.view.center);
  imageRaster.onError = function(err: Error) {
    console.error(`Error in image raster`, err);
  };
  imageRaster.onMouseUp = function(event: paper.MouseEvent) {
    console.log(`Clicked raster`, event.point);
  };
  imageRaster.onDoubleClick = function() {
    fitViewToImage(scope, imageRaster);
  };
  return imageRaster;
}

export const useRasterAndLabels = ({
  assetId,
  imageData,
  labels,
  currentTime,
  scope
}: {
  assetId: Ref<string>;
  imageData: Ref<ImageData>;
  labels: Ref<VideoAnnotationLabel[]>;
  scope: Ref<paper.PaperScope>;
  currentTime: Ref<number>;
}) => {
  const imageRaster = ref(null as paper.Raster | null);

  const { upsertPathForLabel } = useHitManager({
    assetId,
    raster: imageRaster
  });

  function drawLabels(labels: VideoAnnotationLabel[]) {
    /** This could also draw when time updates or image raster updates */
    if (!scope.value) {
      throw Error('Expected scope to be defined');
    }
    // console.log(`Drawing labels`, labels);
    const time = currentTime.value;

    // Find the labels for current time and draw
    labels.forEach(label => {
      upsertPathForLabel(time, label);
    });
  }

  function drawImageRaster({
    imageData
  }: {
    imageData: ImageData;
  }): paper.Raster {
    if (!scope.value) {
      throw Error('Expected scope to be defined');
    }

    if (!imageRaster.value) {
      imageRaster.value = createImageRaster(scope.value);
    }

    imageRaster.value.width = imageData.width;
    imageRaster.value.height = imageData.height;
    imageRaster.value.setImageData(imageData, new paper.Point(0, 0));

    function translateToOrigin() {
      imageRaster.value.translate(
        new paper.Point(
          -imageRaster.value.bounds.x,
          -imageRaster.value.bounds.y
        )
      );
    }

    translateToOrigin();

    return imageRaster.value;
  }

  async function draw() {
    await until(scope).toBeTruthy();
    drawImageRaster({ imageData: imageData.value });
    drawLabels(labels.value);
  }

  watch(imageData, draw, { immediate: true });

  watch(
    assetId,
    async function() {
      await until(imageRaster).toBeTruthy();
      fitViewToImage(scope.value, imageRaster.value);
    },
    { immediate: true }
  );

  return { raster: imageRaster };
};

export const useTools = ({
  scope,
  raster,
  setCursor
}: {
  scope: Ref<paper.PaperScope>;
  raster: Ref<paper.Raster>;
  setCursor: (cursor: string) => void;
}) => {
  const toolConnector = ref(ANNOTATION_TOOLS[0]);
  const tool = ref(null as MouseTool);

  async function activateTool() {
    await until(scope).toBeTruthy();
    await until(raster).toBeTruthy();
    if (tool.value) {
      tool.value.deactivate();
    }
    tool.value = toolConnector.value.build(scope.value, raster.value, {
      setCursor
    });
  }
  watch(toolConnector, activateTool, { immediate: true });

  return { toolConnector, tool };
};

const ZOOM_FACTOR = 1.5;

function zoomBy(factor: number, scope: paper.PaperScope, point: paper.Point) {
  const c = scope.view.center;
  const p = point;
  const pc = p.subtract(c);
  const beta = 1.0 / factor;
  const a = p.subtract(pc.multiply(beta)).subtract(c);
  scope.view.zoom = scope.view.zoom / beta;
  scope.view.center = c.add(a);
}

function zoomInTo(scope: paper.PaperScope, point: paper.Point) {
  zoomBy(ZOOM_FACTOR, scope, point);
}

function zoomOutTo(scope: paper.PaperScope, point: paper.Point) {
  zoomBy(1.0 / ZOOM_FACTOR, scope, point);
}

export const useMouseWheel = (scope: Ref<paper.PaperScope>) => {
  function handleMouseWheel(event: WheelEvent) {
    if (!scope.value) {
      return;
    }
    const mousePosition = new paper.Point(event.offsetX, event.offsetY);
    const viewPosition = paper.view.viewToProject(mousePosition);

    if (event.deltaY > 0) {
      zoomOutTo(unref(scope), viewPosition);
    } else {
      zoomInTo(unref(scope), viewPosition);
    }
  }

  return { handleMouseWheel };
};
