import paper from 'paper';

export const enum GeometryKind {
  Box = 'box'
}

interface BoundingBox {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface BoxGeometry {
  kind: GeometryKind.Box;
  box: BoundingBox;
}

export class Box {
  public readonly rectangle: paper.Rectangle;

  private constructor(rectangle: paper.Rectangle) {
    this.rectangle = rectangle;
  }

  public get x() {
    return this.rectangle.topLeft.x;
  }

  public get y() {
    return this.rectangle.topLeft.y;
  }

  public get width() {
    return this.rectangle.width;
  }

  public get height() {
    return this.rectangle.height;
  }

  public get x1() {
    return this.rectangle.topLeft.x;
  }

  public get y1() {
    return this.rectangle.topLeft.y;
  }

  public get x2() {
    return this.rectangle.bottomRight.x;
  }

  public get y2() {
    return this.rectangle.bottomRight.y;
  }

  public static fromPaperRectangle(rectangle: paper.Rectangle) {
    return new Box(rectangle);
  }

  public expand(x: number): Box {
    return Box.fromPaperRectangle(this.rectangle.expand(x));
  }

  public contains(other: Box): boolean {
    return this.rectangle.contains(other.rectangle);
  }

  /** Interpolate box between times. At time 'from', return a copy of self. At time 'to', returns a copy of 'final'. */
  public interpolateTo({
    at,
    from,
    to,
    final
  }: {
    at: number;
    from: number;
    to: number;
    final: Box;
  }): Box {
    const interpolateNumber = (
      from: number,
      fromValue: number,
      to: number,
      toValue: number,
      at: number
    ) => {
      return fromValue + ((at - from) / (to - from)) * (toValue - fromValue);
    };

    const x1 = interpolateNumber(from, this.x1, to, final.x1, at);
    const y1 = interpolateNumber(from, this.y1, to, final.y1, at);
    const x2 = interpolateNumber(from, this.x2, to, final.x2, at);
    const y2 = interpolateNumber(from, this.y2, to, final.y2, at);

    return Box.fromXYWH({ x: x1, y: y1, width: x2 - x1, height: y2 - y1 });
  }

  public static fromXYWH(data: {
    x: number;
    y: number;
    width: number;
    height: number;
  }): Box {
    return new Box(new paper.Rectangle(data));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public equals(o: any): boolean {
    if (!(o instanceof Box)) {
      return false;
    }

    return this.rectangle.equals(o.rectangle);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  equalWithinTolerance(other: any, tolerance = 1e-5): boolean {
    if (!(other instanceof Box)) {
      return false;
    }

    return (
      this.expand(tolerance).contains(other) &&
      other.expand(tolerance).contains(this)
    );
  }
}

export const scaleToRelativeBox = (
  boxIn: paper.Rectangle,
  imageBounds: paper.Rectangle
): paper.Rectangle => {
  const bounds = boxIn;
  const x1 = bounds.topLeft.x;
  const x2 = bounds.topRight.x;
  const y1 = bounds.topLeft.y;
  const y2 = bounds.bottomLeft.y;

  const width = imageBounds.width;
  const height = imageBounds.height;

  const imageTopLeftX = imageBounds.topLeft.x;
  const imageTopLeftY = imageBounds.topLeft.y;

  const scaledX1 = (x1 - imageTopLeftX) / width;
  const scaledX2 = (x2 - imageTopLeftX) / width;
  const scaledY1 = (y1 - imageTopLeftY) / height;
  const scaledY2 = (y2 - imageTopLeftY) / height;

  const scaledBounds = new paper.Rectangle(
    new paper.Point(scaledX1, scaledY1),
    new paper.Size(scaledX2 - scaledX1, scaledY2 - scaledY1)
  );
  return scaledBounds;
};

export const scaleRelativeBoxToImage = (
  relative: paper.Rectangle,
  imageBounds: paper.Rectangle
): paper.Rectangle => {
  const imageX = imageBounds.x;
  const imageY = imageBounds.y;
  const imageWidth = imageBounds.width;
  const imageHeight = imageBounds.height;

  const inImageBox = new paper.Rectangle(
    imageX + imageWidth * relative.x,
    imageY + imageHeight * relative.y,
    imageWidth * relative.width,
    imageHeight * relative.height
  );
  return inImageBox;
};
