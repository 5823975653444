import paper from 'paper';
import { ref, Ref, watch } from '@vue/composition-api';
import _zip from 'lodash/zip';

import { scaleRelativeBoxToImage } from '../geometry';
import {
  VideoAnnotationGeometry,
  VideoAnnotationLabel,
  VideoAnnotationLabelFrame
} from './types';

export function findHitGeometryOrNone(
  time: number,
  frames: VideoAnnotationLabelFrame[]
): VideoAnnotationGeometry | undefined {
  const starts = frames.slice(0, frames.length - 1);
  const ends = frames.slice(1, frames.length);

  const zipped = _zip(starts, ends);

  for (const [start, end] of zipped) {
    const startTime = start.timeOffset;
    const endTime = end.timeOffset;
    if (startTime <= time && endTime >= time) {
      return start.geometry.interpolateTo({
        at: time,
        from: start.timeOffset,
        to: end.timeOffset,
        final: end.geometry
      });
    }
  }

  return undefined;
}

export function addPathForRectangle(rectangle: paper.Rectangle): paper.Path {
  const path = new paper.Path.Rectangle(rectangle);
  path.strokeColor = new paper.Color('black');
  return path;
}

export const useHitManager = ({
  assetId,
  raster
}: {
  assetId: Ref<string>;
  raster: Ref<paper.Raster>;
}) => {
  const pathByObjectId = ref({} as Record<string, paper.Path>);

  function deletePaths() {
    Object.values(pathByObjectId.value).forEach(path => path.remove());
    pathByObjectId.value = {};
  }

  watch(assetId, deletePaths, {
    immediate: true
  });

  function upsertPathForLabel(time: number, label: VideoAnnotationLabel) {
    const objectId = label.objectId;
    const frames = label.frames;
    const pathOrNone = pathByObjectId.value[objectId];

    const hit = findHitGeometryOrNone(time, frames);

    if (!hit && pathOrNone) {
      pathOrNone.remove();
      pathByObjectId.value = { ...pathByObjectId.value, [objectId]: null };
      return;
    } else if (!hit && !pathOrNone) {
      // No hit, no path
      return;
    } else if (hit && !pathOrNone) {
      // Create new path
      const rectangle = hit.rectangle;
      const scaled = scaleRelativeBoxToImage(rectangle, raster.value.bounds);

      const path = addPathForRectangle(scaled);

      pathByObjectId.value = { ...pathByObjectId.value, [objectId]: path };
    } else if (hit && pathOrNone) {
      const rectangle = hit.rectangle;
      // TODO Update position instead of drawing new rectangle?
      pathOrNone.remove();

      const scaled = scaleRelativeBoxToImage(rectangle, raster.value.bounds);
      const path = addPathForRectangle(scaled);
      pathByObjectId.value = { ...pathByObjectId.value, [objectId]: path };
    }
  }

  return { upsertPathForLabel, paths: pathByObjectId };
};
