










import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  Ref,
  ref,
  toRefs,
  watch
} from '@vue/composition-api';

import { Asset, FetchMedia } from '@/types';

import { useActiveVideoMedia } from './functions';
import { until } from '@vueuse/core';
import LoadingModal from '../LoadingModal.vue';

export default defineComponent({
  components: { LoadingModal },
  name: 'VideoProvider',
  props: {
    playing: {
      required: true,
      type: Boolean
    },
    asset: {
      required: true,
      type: Object as PropType<Asset>
    },
    fetchMedia: {
      required: true,
      type: Function as PropType<FetchMedia>
    },
    currentTime: {
      required: true,
      type: Number
    },
    targetTime: {
      required: true,
      type: Number
    }
  },
  setup(props, { emit }) {
    const { asset, targetTime, playing, fetchMedia } = toRefs(props);

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const {
      src,
      loading: loadingMedia,
      error: errorLoadingMedia,
      mediaType
    } = useActiveVideoMedia(asset, fetchMedia);

    const player = ref(null) as Ref<any>;
    const videoPlayerRef = ref(null);

    const imageData = ref(null as ImageData | null);

    const videoElement = ref(null as HTMLVideoElement);

    const looping = ref(false);

    async function updatePlaying() {
      // eslint-disable-next-line
      // @ts-ignore
      await until(player).toBeTruthy();
      if (playing.value) {
        player.value.play();
        looping.value = true;
      } else {
        player.value.pause();
        looping.value = false;
      }
    }

    watch(playing, updatePlaying, { immediate: true });

    watch(targetTime, async function() {
      // eslint-disable-next-line
      // @ts-ignore
      await until(player.value).toBeTruthy();
      console.log(`Seeking to time`, targetTime.value);
      player.value.currentTime(targetTime.value);
    });

    function updateImageData() {
      const videoInternalWidth = player.value.videoWidth();
      const videoInternalHeight = player.value.videoHeight();

      if (videoInternalWidth === 0) {
        imageData.value = null;
        return;
      }

      canvas.width = videoInternalWidth;
      canvas.height = videoInternalHeight;

      ctx.drawImage(
        videoElement.value,
        0,
        0,
        videoInternalWidth,
        videoInternalHeight,
        0,
        0,
        canvas.width,
        canvas.height
      );
      imageData.value = ctx.getImageData(0, 0, canvas.width, canvas.height);
    }

    async function loopUpdateImageData() {
      updateImageData();
      if (looping.value) {
        player.value.requestAnimationFrame(loopUpdateImageData);
      }
    }

    watch(
      looping,
      function() {
        if (looping.value) {
          loopUpdateImageData();
        }
      },
      { immediate: true }
    );

    function updatePlayerSrc() {
      if (!player.value || !src.value || !mediaType.value) {
        return;
      }
      player.value.src({ src: src.value, type: mediaType.value });
      updatePlaying();
    }

    function initializePlayer() {
      const options = computed(() => ({
        autoplay: false,
        controls: false,
        preload: 'auto',
        sources: []
      }));
      return new Promise(resolve => {
        player.value = videojs(
          videoPlayerRef.value,
          options.value,
          function onPlayerReady() {
            console.log('Player ready');
            updatePlayerSrc();
            resolve(player.value);
          }
        );
        videoElement.value = player.value.children_[0];

        player.value.on('timeupdate', () => {
          emit('update:currentTime', player.value.currentTime());
        });

        player.value.on('durationchange', () => {
          emit('update:duration', player.value.duration());
        });

        player.value.on('canplay', () => {
          console.log(`Player ready to play`);
          updateImageData();
        });

        player.value.on('error', err => {
          console.error(`Video.js player error`, err);
        });

        player.value.on('ended', () => {
          emit('update:playing', false);
        });
      });
    }

    onMounted(async () => {
      await until(src).toBeTruthy();
      await initializePlayer();
      updateImageData();
    });

    watch([src, mediaType], updatePlayerSrc, { immediate: false });

    onUnmounted(() => {
      if (player.value) {
        console.log(`Disposing video player`);
        player.value.dispose();
        canvas.remove();
      }
    });

    return {
      src,
      videoPlayerRef,
      imageData,
      loading: loadingMedia,
      error: errorLoadingMedia,
      player
    };
  }
});
