import paper from 'paper';

const ZOOM_IN_KEY = '+';
const ZOOM_OUT_KEY = '-';
const PAN_TOGGLE_KEY = 'space';

const FILTER_ALPHA = 0.8;

export interface MouseTool {
  deactivate(): void;
}

export interface MouseToolOptions {
  setCursor(cursor: string): void;
  onKeyUp?(event: paper.KeyEvent): void;
}

export interface ToolUIConnector {
  build: (
    scope: paper.PaperScope,
    raster: paper.Raster,
    options?: Partial<MouseToolOptions>
  ) => MouseTool;
  name: string;
  shortcut?: string;
  helptext?: string;
  icon: string;
}

let mousePoint: paper.Point = undefined;

export function createPaperTool({
  onKeyUp,
  onKeyDown,
  onMouseDown,
  onMouseUp,
  onMouseDrag,
  onMouseMove,
  options,
  cursor,
  scope,
  raster
}: {
  onKeyUp?: (ev: paper.KeyEvent) => void;
  onKeyDown?: (ev: paper.KeyEvent) => void;
  onMouseDown?: (ev: paper.MouseEvent) => void;
  onMouseUp?: (ev: paper.MouseEvent) => void;
  onMouseDrag?: (ev: paper.MouseEvent) => void;
  onMouseMove?: (ev: paper.MouseEvent) => void;
  options?: Partial<MouseToolOptions>;
  cursor: string;
  scope: paper.PaperScope;
  raster: paper.Raster;
}): paper.Tool {
  let isPanning = false;
  let prevDelta: paper.Point = undefined;

  const tool = new paper.Tool();

  const doPanning = (ev: paper.MouseEvent) => {
    /**
     * Perform simple low-pass filtering as the delta
     * points fluctuate around origin when dragging
     */

    const delta = ev.delta;

    if (prevDelta) {
      const newDelta = prevDelta.add(
        delta.subtract(prevDelta).multiply(FILTER_ALPHA)
      );
      prevDelta = newDelta;
      scope.view.center = scope.view.center.add(newDelta.multiply(-1));
    } else {
      prevDelta = delta;
    }
  };

  tool.onKeyDown = (event: paper.KeyEvent) => {
    if (!mousePoint) {
      return;
    }
    switch (event.key) {
      case PAN_TOGGLE_KEY:
        options?.setCursor?.('grab');
        isPanning = true;
        event.preventDefault();
        break;
    }
    onKeyDown?.(event);
  };

  tool.onKeyUp = (event: paper.KeyEvent) => {
    switch (event.key) {
      case PAN_TOGGLE_KEY:
        // Restore cursor
        options?.setCursor?.(cursor);
        isPanning = false;
        break;
    }
    options?.onKeyUp?.(event);
    onKeyUp?.(event);
  };

  tool.onMouseDown = (ev: paper.MouseEvent) => {
    if (isPanning) {
      prevDelta = null;
    } else {
      onMouseDown?.(ev);
    }
  };

  tool.onMouseUp = (ev: paper.MouseEvent) => {
    onMouseUp?.(ev);
  };

  tool.onMouseDrag = (ev: paper.MouseEvent) => {
    if (isPanning) {
      doPanning(ev);
    } else {
      onMouseDrag?.(ev);
    }
  };

  tool.onMouseMove = (ev: paper.MouseEvent) => {
    mousePoint = ev.point;
    if (isPanning) {
      options?.setCursor?.('grab');
    } else {
      onMouseMove?.(ev);
    }
  };

  return tool;
}

export class SelectObjectTool implements MouseTool {
  private static readonly CURSOR = 'pointer';
  private paperTool: paper.Tool;

  constructor(
    scope: paper.PaperScope,
    raster: paper.Raster,
    options: MouseToolOptions
  ) {
    this.paperTool = this.setupPaperTool(scope, raster, options);
  }

  private get cursor() {
    return 'pointer';
  }

  private setupPaperTool(
    scope: paper.PaperScope,
    raster: paper.Raster,
    options: MouseToolOptions
  ) {
    const tool = createPaperTool({
      scope,
      raster,
      options: options,
      cursor: this.cursor
    });
    tool.minDistance = 1;
    return tool;
  }

  deactivate() {
    this.paperTool.remove();
  }
}

export const selectObject: ToolUIConnector = {
  build: (
    scope: paper.PaperScope,
    raster: paper.Raster,
    options: MouseToolOptions
  ) => new SelectObjectTool(scope, raster, options),
  name: 'Select',
  shortcut: 's',
  icon: 'mdi-cursor-pointer'
};

export const ANNOTATION_TOOLS: ToolUIConnector[] = [selectObject];
