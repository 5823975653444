















import paper from 'paper';
import {
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  reactive,
  ref,
  toRefs
} from '@vue/composition-api';

import { VideoAnnotationLabel } from './types';
import { useRasterAndLabels, useTools, useMouseWheel } from './functions';
import MouseWheel from './MouseWheel.vue';

export default defineComponent({
  name: 'VideoPlaybackView',
  components: { MouseWheel },
  props: {
    assetId: {
      required: true,
      type: String
    },
    playing: {
      required: true,
      type: Boolean
    },
    imageData: {
      required: true,
      type: ImageData
    },
    labels: {
      required: true,
      type: Array as PropType<VideoAnnotationLabel[]>
    },
    currentTime: {
      required: true,
      type: Number
    }
  },
  setup(props) {
    const { assetId, imageData, labels, currentTime } = toRefs(props);

    const drawCanvasRef = ref(null as HTMLCanvasElement);

    const scope = ref(null as paper.PaperScope);

    const { raster: imageRaster } = useRasterAndLabels({
      assetId,
      scope,
      imageData,
      labels,
      currentTime
    });

    const canvasStyle = reactive({ cursor: 'pointer' });

    function setCursor(cursor: string) {
      canvasStyle.cursor = cursor;
    }

    const { tool } = useTools({ raster: imageRaster, scope, setCursor });

    const { handleMouseWheel } = useMouseWheel(scope);

    async function setupDrawCanvas() {
      const canvas = drawCanvasRef.value;
      if (scope.value) {
        imageRaster.value.remove();
        scope.value.project.remove();
      }
      console.log(`Setting up paper.js scope`);
      paper.setup(canvas);
      scope.value = paper;
    }

    onMounted(() => {
      setupDrawCanvas();
    });

    onUnmounted(() => {
      if (scope.value) {
        scope.value.project.remove();
        scope.value = null;
      }
    });

    return {
      drawCanvasRef,
      scope,
      imageRaster,
      handleMouseWheel,
      canvasStyle,
      setCursor
    };
  }
});
